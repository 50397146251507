@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i);
/* import 'color.js'; */

body,
html {
  overflow-x: hidden;
  background: rgb(250, 250, 250);
  /* margin-right: 5px; */
}

.round_test {
  border-radius: 5px !important;
}

.round-card {
  border-radius: 7px !important;
}

.reset-button {
  color: #fff;
  background-color: #f35b04;
}

.logout-button-css:hover {
  background-color: #f35b04 !important;
  border: 2px solid #f35b04;
  color: white;
}

.logout-button-css {
  border: 2px solid black;
}

.btn-primary {
  background-color: #e65806 !important;
  border-color: #e65806 !important;
}

h1 {
  color: #172b4c !important;
}

h3,
h4 {
  color: #5f6368 !important;
}

/* .shadow{
    box-shadow: .001rem 1rem rgba(0,0,0,.15) !important; 
} */

.human_body {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 4;
  border: 2px solid black;
  border-radius: 10px;
  background: rgb(250, 250, 250);
}

.navIcon {
  line-height: 0%;
  -webkit-transform: translate(-12%, 10%);
          transform: translate(-12%, 10%);
}

.bg-new3 {
  background-color: rgb(252, 245, 237);
}

.bg-new4 {
  background-color: rgb(85, 137, 252);
}
.bg-new5 {
  background-color: rgb(12, 0, 179);
}
.bg-new6 {
  background-color: rgb(201, 16, 185);
}
.bg-new7 {
  background-color: rgb(0, 0, 0);
}
.set {
  overflow: hidden;
}
.footer1 {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(212, 157, 121);
  text-align: center;
}
.footer2 {
  background-color: rgb(134, 96, 73);
  text-align: center;
  height: auto;
}
.btn-xl {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: none;
  line-height: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-right: 15px;
  margin-left: 15px;
}

.active,
.btn-xl:hover {
  box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.2),
    5px 10px 23px 0 rgba(0, 0, 0, 0.19);
}
.changecolor {
  color: white;
  display: inline;
  margin-left: 50px;
}
.navbar-collapse.collapse.show {
  display: block !important;
}

.flexcard {
  width: 50%;
  border-radius: 10px;
}

.bottom_left {
  background: rgb(222, 219, 4);
}

.th {
  width: 2vw;
  font-size: x-large;
}

.thfirst {
  width: 50%;
}

.phalf {
  padding: 1px 5px 1px 5px;
  box-shadow: 1px 1px 1px #888888;
  border-radius: 2px;
}

table {
  box-shadow: 3px 3px 5px #eee;
  border-radius: 10px;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 2px solid lightgrey;
}

table th {
  font-size: x-large;
}

.table-white-background {
  background-color: #fff !important;
}

.border-dark {
  border: 1px solid white !important;
}

.accordion {
  cursor: pointer !important;
}

#table1,
#table2,
#table3 {
  width: 25vw;
}

.tableHead {
  font-family: "Ubuntu", sans-serif;
}

.bg-success-imp {
  background: rgba(0, 200, 0, 255);
}

.bg-warning-imp {
  background: rgba(251, 183, 45, 255);
}

.bg-danger-imp {
  background: rgba(255, 145, 83, 255);
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.text-font {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 550;
}

.text-font-heading {
  font-family: "Nunito Sans", sans-serif;
  font-weight: bolder;
}

.reduce_width {
  padding: 80px !important;
  padding-bottom: 20px !important;
  /* height: 95%; */

  /* width: 50px; */
  /* height: 100%; */
}
.lipids-height {
  height: 73%;
}

.pop_up ::-webkit-scrollbar-thumb {
  background: rgb(247, 243, 243);
}

@media all and (max-width: 990px) {
  .custom-class {
    width: 100%;
    display: block;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: rgb(252, 245, 237);
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(170, 171, 175);
}

@media all and (max-width: 766px) {
  .design img {
    width: 10vw;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  #table1,
  #table2,
  #table3 {
    width: 65vw;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}

.fix_height {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.img-icon {
  margin-right: 20px;
  width: 45px;
  /* margin-top: 0px;
    margin-bottom: 2px; */
}

.low-padding {
  padding-bottom: 100px;
}

.highlight {
  background-color: rgb(206, 205, 205);
}

.minimise-size {
  -webkit-text-size-adjust: 50px;
     -moz-text-size-adjust: 50px;
          text-size-adjust: 50px;
}

.left-aline {
  text-align: left;
}
.aline-table {
  margin-left: 33%;
}

.upDownButton:focus {
  outline: none;
}
.upDownButton {
  padding: 2px;
  /* margin-left: 3px;
    margin-right: 3px; */
  border-style: none;
  /* background-color: rgb(252, 245, 237); */
}

.right-side {
  margin-left: 7% !important;
}

.right-side-name {
  margin-left: 20px;
  width: 25px;
  height: 25px;
}
.left-margin {
  margin-left: 10px;
}

.top-margin {
  margin-top: 100px;
}
.testing {
  background-color: red;
}

.margin-below {
  /* position: fixed; */
  /* margin-top: 100px; */
  margin-bottom: 0px;
  /* margin-top: 50px; */
}

.test {
  background-color: black;
}

.padd {
  padding-bottom: 192px;
}

.handle-insight-images {
  /* border-radius: 50%; */
  height: 48px;
  width: 47px;
  margin-left: 16%;
}

.footer_term {
  text-decoration: none !important;
  color: #04d9ff;
}
.table-margin {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.emoji {
  margin-left: 10px;
}
.text-row {
  padding-bottom: 10px;
}
.visit-link {
  margin-left: 15px;
}

.headings {
  /* display: inline-block; */
  margin-top: 6%;
  margin-bottom: 5%;
  margin-left: 1%;
  margin-right: 1%;
}

.button {
  display: inline-block;
  margin-left: 215px;
  /* margin-bottom: 50px; */
  margin-top: 0px;
  /* padding-bottom: 50px; */
}

select {
  width: 90%;
}
.bold-heading {
  font-weight: bold;
}
.insights-button {
  padding: 0.675rem 0.75rem !important;
}
.download_csv_btn {
  /* display: inline-block; */
  border-radius: 10px;
}
.download_csv_btn_real {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  border-radius: 50px;
  margin-left: 150px;
}
.height-fix {
  display: inline-block;
  width: 630px;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.horizontal_slider {
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: pre;
  /* margin-bottom: 50px; */
  margin-top: 50px;
  padding-bottom: 30px;
}

.horizontal_slider::-webkit-scrollbar {
  display: -webkit-box;
}

.item {
  display: inline-block;
  margin-right: 50px;
  white-space: normal;
}

.insight_download_button {
  background-color: rgb(50, 194, 13);
  color: white;
  border-radius: 10px;
  /* /* padding-left: 20px;
    padding-right: 20px; */
  /* padding-top: 10px; 
    padding-bottom: 10px; */
  /* margin-left: 430px; */
}

.insights_heading_scroll_left {
  margin-left: 20px;
  margin-top: 50px;
}

.heading_profile {
  margin-bottom: 30px;
  margin-top: 40px;
  display: inline-block;
}

.insight-table-curve {
  border-radius: 10px !important;
}

.insight-i-icon {
  margin-left: 10px;
  fill: rgb(163, 155, 155);
}

.leaders-i-icon {
  fill: rgb(0, 234, 0);
}

.learners-i-icon {
  fill: rgb(251, 230, 45);
}

.laggards-i-icon {
  fill: rgb(245, 107, 27);
}

.login-card-round {
  border-radius: 4%;
}

.login-margin {
  margin-top: 16%;
  margin-bottom: 10%;
}

.login-button {
  height: 65px;
}

.background-white {
  background-color: white;
}

.test-bottom-margin {
  margin-bottom: 2%;
}

.howto-spacing {
  word-spacing: 2px;
}

.material-textfield {
  position: relative;
}

.material-textfield label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-top-color: transparent;
  background-color: white;
  color: gray;
  padding: 0 0.1rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  pointer-events: none;
}
.material-textfield input {
  font-size: 1rem;
  outline: none;
  border: 1px solid rgb(187, 183, 183);
  border-radius: 5px;
  padding: 28px 13px 28px;
  color: gray;
  transition: 0.1s ease-out;
}
.material-textfield input:focus {
  border: 2px solid rgb(33, 150, 243);
}
.material-textfield input:focus + label {
  /* color: rgb(33, 150, 243); */
  top: 0;
  -webkit-transform: translateY(-50%) scale(0.9);
          transform: translateY(-50%) scale(0.9);
}
.material-textfield input:not(:-ms-input-placeholder) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.material-textfield input:not(:placeholder-shown) + label {
  top: 0;
  -webkit-transform: translateY(-50%) scale(0.9);
          transform: translateY(-50%) scale(0.9);
}

.demographics-icon-margin {
  margin-left: 5%;
}

.round {
  position: relative;
  margin-left: 20%;
}

.round label {
  background-color: #ecf0f4;
  border: 2px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.round label:after {
  border: 3px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 7px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 5px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #2196f3;
  border-color: #1d94f5;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.icon-image {
  width: 75%;
}
@media screen and (max-width: 650px) {
  .div-covers {
    display: flex;
    flex-direction: column-reverse;
  }

  .padd {
    padding-bottom: 0px;
  }

  .icon-image {
    width: 50px;
    margin-left: 10%;
  }

  .insight-table-responsive-margin {
    margin-left: -2%;
  }

  .aline-table {
    margin-left: 18%;
  }
  .test-row-margin {
    margin-right: -15% !important;
  }
}

@media screen and (max-width: 360px) {
  .insight-table-responsive-margin {
    margin-left: -2%;
  }
}

.drive-css {
  margin: 4px 15px 0px 15px;
}

.drive-css:hover {
  color: black;
  cursor: pointer;
}

.drive-links-css {
  color: black;
}

.drive-links-css:hover {
  color: dodgerblue;
  cursor: pointer;
}

.signout-css {
  color: black;
}

.signout-css:hover {
  color: white;
}

.excel-css {

}

.excel-css:hover {
  margin: 3px 0px 0px 3px; 
}

@media (max-width: 500px) {
  .nav-item-css {
    display: flex;
    justify-content: center;
  }
}

@media not all and (max-width: 500px) {
  .nav-item-css {
    margin-left: 6px;
  }
}
.fontchange {
  font-family: 'Inter', sans-serif;
}

.bolded {
  font-family: 'DM Sans', sans-serif;
}


.margin_top{
  margin-top: 20px;
}

.unselectable-text{
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  user-select: none;
}

.my-toggle-btn-wrapper{
  text-align: center;
}
.my-toggle-btn{
  cursor: pointer;
  display: inline-block;
}
/* .my-toggle-btn input[type=checkbox]{
  display: none;
} */
.my-toggle-btn input[id=checkbox2]{
  display: none;
}
.my-toggle-btn input[id=checkbox1]{
  display: none;
}
.my-toggle-btn input[id=checkbox3]{
  display: none;
}
.my-toggle-btn label{
  background: rgba(218,219,219,255);
  color: #000;
  width: 120px;
  height: 34px;
  font-size: 22px;
  display: inline-block;
  border-radius: 20px;
  position: relative;
  text-align: center;
  transition: all .4s ease;
  cursor: pointer;
}
.my-toggle-btn label:before{
  border-radius: 20px;
  content: '';
  position: absolute;
  height: 30px;
  width: 25px;
  background: #444;
  left: 2px;
  top: 1px;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
}
.my-toggle-btn input[type=checkbox]:checked + label:before{
  left: 92px;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
}
.my-toggle-btn input[type=checkbox]:checked + label span.off{
  display: none;
}
.my-toggle-btn input[type=checkbox]:checked + label span.on{
  display: inline;
}
.my-toggle-btn input[type=checkbox] + label span.on{
  display: none;
}
.my-toggle-btn input[type=checkbox] + label span.off{
  display: inline;
}

.my-toggle-btn input[type=checkbox]:checked + label{
  background: rgba(147,211,68,255);
}
.progress-bar-css1{
  height:10px;
}
.progress-bar-css2{
  width:50%;
  background-color: yellow;
}

.checkbox {
  position: absolute;
  top: 0vh;
  left: 6vw;
  opacity: 0.5;
  cursor: pointer;
  height: 80px;
  width: 80px;
  z-index: 2;
  border: none;
  outline: 0;
}

@media screen and (max-width:450px){
  .checkbox {
    top: 0vh;
    left: 10.5vw;
    opacity: 0.5;
    cursor: pointer;
    height: 80px;
    width: 80px;
    z-index: 2;
    border: none;
    outline: 0;
  }
}

.containerz {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerz input {
  display: none;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 4vw;
  height: 22px;
  width: 22px;
  /* border: 1px solid black; */
  border-radius: 50%;
  background-color: #ecf0f4;
}
.containerz:hover input ~ .checkmark {
  background-color: #ccc;
}

.containerz input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerz input:checked ~ .checkmark:after {
  display: block;
}
.containerz .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

input:checked ~ .checkmark:after {
  display: block;
} 


/* .checkmark:after {
  content: "✔";
  background-color: #2196F3;
  border: 1px solid black;
  position: absolute;
  display: none;
} */


/* When the checkbox is checked, add a blue background */
/* input:checked ~ .checkmark {
  background-color: #2196F3;
}

input:disabled ~ .checkmark {
  border: 0;
  background-color: #eee;
}

input:disabled ~ .checkmark:hover {
  cursor: not-allowed
}

/* Show the checkmark when checked */




.containers {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
}

/* Hide the browser's default checkbox */
.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarks {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgrey;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containers:hover input ~ .checkmarks {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containers input:checked ~ .checkmarks {
  border: 3px solid #2196F3;
  background-color: #afd9fa;
}

/* Show the checkmark when checked */
.containers input:checked ~ .checkmarks:after {
  display: block;
}

/* Style the checkmark/indicator */
.containers .checkmarks:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.reducesize{
  width: 60%;
  margin-top: 10px;
}

.paddinginter{
  padding-left: 10px;
  padding-right: 10px;

}

.upper_row{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px;
}

.margin-fix{
  margin-left: 10px;
  margin-right: 15px;
}

.blue_background{
  background: #2196F3;
}

.center{
  align-items: center;
}

.hover-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.hover-container > div{
    overflow: hidden;
}

.hover-card {
    position: relative;
}

.span {
    visibility: hidden;
}

.hover-card .span {
    position: absolute;
    /* top: -4vw;
    left: 0vw; */
    color: black;
    text-align: center;
    opacity: 0.9;
    font-size: 1rem;
    font-family: 'Ubuntu', sans-serif;
}

.hover-card:hover .span{
    top: 1vw;
    opacity: 0.9;
    visibility: visible;
    transition: all 0.5s ease-in-out;
}

.hover-card .img-opacity{
    opacity: 1;
}

.hover-card:hover .img-opacity {
    opacity: 0.1;
}
.custom_font {
  font-family: 'Source Sans Pro', sans-serif;
}

.safe {
  color: green;
}

.warning {
  color: orange;
  font-size: 30px;
}

.danger {
  color: red;
  font-size: 30px;
}

.human_body {

  background: rgb(250, 250, 250);
  width: 40vw; 
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  border: 200px solid rgb(109, 107, 107);
  */

  display: flex;
  justify-content: center;
  align-items: center;
 

   /* display: relative;
   top:50%;
   left: 50%; */

   -webkit-filter: none;

           filter: none;

}

.bg-eee {
  background: rgb(250, 250, 250);
}

.hover_change:hover {
  /* color:blue; */
  cursor: pointer;
  background-color:rgb(233, 252, 252) ;
  
}

.card-img-top {
  width: 70px;
  height: 76px;
}

.triangle-up {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 13px solid #555;
}

.triangle-down {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 13px solid #555;
}


@media (max-width: 450px) {
  .hidden-xs {
    display: none !important;
  }
}

.testing{
  background-color: red;
}

.cardHighlight:hover
{
  background-color:wheat;
}

.remove-left-margin{
    padding-right: 55px;
    padding-left: 0px;
}

.card-text-fix{

  text-align: left;
  margin-left: 25%;
}

.scroll{
  max-height: 250px;
  overflow-y: scroll;
}

.margin_left{
  margin-left: 35px;
}

.black{
  color: black;
}

.grey{
  color: rgb(143, 142, 142);
}

.pop_up{
  border: none;
  -webkit-filter: none;
          filter: none;
  width: 55%;
}

.download-file-css {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  margin: 5px 0px 0px 12px;
  color: #f35b04;
  font-size: 22px;
}

.download-file-css:hover {
  margin: 5px 0px 0px 12px;
  color: #f35b04;
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.download-file-disable-css {
  margin: 5px 0px 0px 12px;
  font-size: 22px;
  color: grey
}

/* body :not(#unblurred){
  filter: blur(2px);
} */
/* .myModal
{
  height: 200px;
  width: 300px;
  background-color: blue;
} */
/*Reset*/
.sect{
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  color: #1f4568;
}

.sect{
background-image: url("https://image.ibb.co/de6JzG/bitmap_1_1.png");
  background-size:cover;
  background-repeat:no-repeat;
  background-position: center;
}

/* colors 8a3aff, 6f79ff, 3a9fff black-> 1f4568  grey->8198ae*/
.sect{
  padding: 90px 0;
  position: relative;
}


.collapse{
 display: block;
}

.sect--violet{
  background-color:#6f79ff;
}

.sect--padding-bottom{
  padding-bottom:115px;
}

.sect--padding-top{
  padding-top:90px;
}

.row--center{
  max-width: 1000px;
  margin: 0 auto;
}
.row--margin{
  margin-top:45px;
}

.row__title{
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  margin-top: 0px;
}

.row__sub{
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 0px; 
  color: #8198ae;
}

.login-btn, .signup-btn {
  background-color:#3a9fff;
  border-radius: 20px;
  padding: 10px 25px;
  color: #fff;
  box-shadow: 0px 18px 13px -6px rgba(0, 0, 0,0.2);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.price-box{
  padding: 0px 25px;  
}

.price-box__wrap{
  background-color: #fff;
  border-radius:6px;
  box-shadow:  0 4px 8px 0px rgba(0,0,0,0.2); /*0px 22px 20px -10px rgba(0, 0, 0,0.2)*/
  padding: 35px 35px 40px 35px;
  text-align:center;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.price-box__img{
  display: inline-block;
}

.price-box__img{
  width:130px;
  height:130px;
  border-radius:50%;
  background-position: center;
    background-size:cover;
  background-repeat:no-repeat;
}



.price-box--purple .price-box__img{
  background-image: url("https://image.ibb.co/iGjEKG/1.png");

}


.price-box--violet .price-box__img{
  background-image: url("https://image.ibb.co/iyT8eG/2.png");

}

.price-box--blue .price-box__img{
  background-image: url("https://preview.ibb.co/nDRpmw/3.png");

}

.price-box__title{
  font-size:22px;
  letter-spacing:1px;
  margin-bottom:4px;
}

.price-box__people{
  font-size:12px;
  color:#8198ae;
  font-weight: 300;
  letter-spacing:1px;
}
.price-box__discount{
  font-size: 28px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 0;
}

.price-box__dollar {
    font-size: 18px;
    font-weight: 400;
    vertical-align: top;
    margin-right: 4px;
}

.price-box--purple .price-box__dollar{
  color: #8a3aff;
}

.price-box--violet .price-box__dollar{
  color: #6f79ff;
}

.price-box--blue .price-box__dollar{
  color: #3a9fff;
}

.price-box__discount--light{
  font-weight: 400;
  font-size: 20px;
  margin-left: 3px;
}

.price-box__price {
    margin-top: 3px;
    font-size: 14px;
    color: #8198ae;
    letter-spacing: 1px;
    margin-bottom: 0;
    font-weight: 300;
    text-decoration: line-through;
}

.price-box__feat {
    margin-top: 30px;
    font-size: 16px;
}

.price-box__list {
    text-align: left;
    padding-left: 30px;
    margin: 0;
    list-style: none;
}

.price-box__list-el{
  position: relative;
  margin-bottom: 8px;
  font-weight: 300;
}

.price-box__list-el:before{
  content: "✓";
  position: absolute;
  left: -25px;
  font-size: 18px;
  top: -4px;
}

.price-box--purple .price-box__list-el:before{
  color: #8a3aff;
}

.price-box--violet .price-box__list-el:before{
  color: #6f79ff;
}

.price-box--blue .price-box__list-el:before{
  color: #3a9fff;
}

.price-box__wrap:hover {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: scale(1.05, 1.05);
          transform: scale(1.05, 1.05);
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
}

.price-box__btn {
    position: absolute;
    bottom: -17px;
    left: 50%;
    margin-left: -55px;
}

.row__sub--light{
  color: #aec0d2;
}
.item__img{
  width:25px;
  margin-right:10px;
}

.row__carousel{
  display:inline-block;
  float:none;
  border-radius: 6px;
}
.row--text-center{
  text-align:center;

}
.carousel-control{
  opacity:1;
  background-image:none !important;
}

.carousel-control.left{
  left:-100px;
}

.carousel-control.right{
  right:-100px;
}

.carousel-inner{
  margin-top: -25px;
}
.carousel-control__img{
 position: absolute;
top: 50%;
margin-top: -90px;
}

.carousel-control.left .carousel-control__img{
  left:0;
}

.carousel-control.right .carousel-control__img{
  right:0;
}

.carousel-indicators li{
  height:12px;
  width:12px;
    background-color: #fff;
    border: 2px solid #8198ae;
  margin-right:8px;
}

.carousel-indicators li.active{
  height:14px;
  width:14px ;
    background-color: #fff;
    border: 3px solid #6f79ff;
  margin-right:10px;
}
.item {
  text-align:left;
  position:relative;
  padding:25px 15px 0px 25px;
}

.item__content{
padding: 40px;
border-radius: 6px;
box-shadow:  0 4px 8px 0px rgba(0,0,0,0.2);
background-color: #fff;
position: relative;
  height: 305px;
}
.item__img{
 display:inline-block;
}
.item__name{
display: inline-block;
font-size: 22px;
vertical-align: middle;
font-weight: 700;
}
.item__description {
    margin-top: 35px;
    color: #8198ae;
    line-height: 23px;
}
.item__avatar{
height: 45px;
width: 45px;
background-image: url("https://static.pexels.com/photos/428333/pexels-photo-428333.jpeg");
background-size: cover;
border-radius: 50%;
margin: 50px auto 15px auto;

}

.carousel-indicators {
    width: auto;
    margin: 0;
    right: 68px;
    text-align: right;
    top: 72px;
  left: auto;
  bottom: auto;
}
.item__content:after{
content: "";
width: 0;
height: 0;
border-left: 20px solid transparent;
border-right: 20px solid transparent;
border-top: 20px solid #fff;
bottom: -20px;
position: absolute;
left: 50%;
margin-left: -23px;
-webkit-filter: drop-shadow(0px 7px 4px rgba(0,0,0,.2));
        filter: drop-shadow(0px 7px 4px rgba(0,0,0,.2));
}

.item__people {
    margin: 0;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
}

.item__occupation {
    font-size: 14px;
    color: #8198ae;
    text-align: center;
}

.site{
  text-align: center;
}
.site__title{
    font-size: 30px;
    margin: 0;
    text-align: center;
}

.site__subtitle{
    font-size: 22px;
    color: #8198ae;
    margin: 15px 0 40px 0;
    text-align: center;
}

.site__img{
  margin-top: 50px;
  display: inline-block;
  width: 77%;
}
.site__box-link .btn--revert{
  margin-left: 15px;
}


/*menu header*/
.header{
  padding: 5px 25px;
}
.header__elenco {
    padding: 0;
    margin: 0;
    list-style: none;
}
.header__menu {
    float: right;
}
.header__el{
    padding: 0 25px;
    display: inline-block;
}
.header__img{
  display: inline-block;
  vertical-align: middle;
}

.header__logo, .header__menu{
  display: inline-block;
  vertical-align: middle;
}
.header__title{
  font-size: 18px;
  margin: 0;
  margin-left: 10px;
  display: inline-block;
  line-height: 18px;
  vertical-align: middle;
}

.header__light{
  color: #8198ae;
}
.header__link{
  color: #1f4568;
  font-size: 14px;

}
.header__link:hover{
  text-decoration: none;
  color:#3a9fff;
}
.navbar-toggle .icon-bar{
  background-color: #8198ae;
}
.navbar-toggle {
    margin: 5px 0;
}
.partner{
  margin-bottom:15px;
  text-align:center;
}
.partner_img{
  width:160px;
  opacity:0.5;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
}

.sect--no-padding{
  padding-top:0;
}

.partner_img:hover{    
    -webkit-transform: scale(1.08, 1.08);    
            transform: scale(1.08, 1.08);
  opacity:0.9;
}

.article-pre__col{
  padding:0 30px;
  margin-bottom:30px;
}

.article-pre__img {
    height: 0;
    background-position: center;
    background-size: cover;
    padding-bottom: 55%;
    border-radius: 6px;
  box-shadow: 0 4px 10px 2px rgba(0,0,0,0.3);
  transition: all 0.4s;
-webkit-transition: all 0.4s;
-moz-transition: all 0.4s;
-ms-transition: all 0.4s;
-o-transition: all 0.4s;
}

.article-pre__img--first{
   background-image: url(https://cdn.dribbble.com/users/18730/screenshots/2182635/newshot.png);
}

.article-pre__img--second{
  background-image: url(https://image.ibb.co/cYsvbw/dribbble.jpg);  
}

.article-pre__img--third{
  background-image: url(https://cdn.dribbble.com/users/103909/screenshots/3671664/sensors-01.jpg);  
}

.article-pre__img--fourth{
  background-image: url(https://cdn.dribbble.com/users/997070/screenshots/4120967/attachments/943230/01-1._Cover_Copy_2x.png);  
}

.article-pre:hover .article-pre__img {
  box-shadow: 0px 10px 13px 4px rgba(0, 0, 0,0.2);
}

.article-pre:hover {
  text-decoration:none;
}
.article-pre__info {
    font-size: 16px;
    color: #8198ae;
  margin: 0;
padding: 25px 25px 0 25px;
}

.article-pre__date {
    float: right;
}

.article-pre__title {
font-size: 20px;
color: #1f4568;
margin: 0;
padding: 15px 25px;
}

.article-pre__arrow--purple{
  color: #8a3aff;
}

.contacts_title-ag {
    margin: 0;
        margin-left: 0px;
    display: inline-block;
    vertical-align: middle;
    font-size: 22px;
    margin-left: 10px;
}

.contacts__link{
  color: #1f4568;
}

.contacts--light {
    color: #8198ae;
}

.contacts__address {
margin-top: 20px;
color: #8198ae;
font-size: 16px;
line-height: 27px;
padding-bottom: 12px;
margin-bottom: 17px;
  position: relative;
}

.contacts__address:after{
  content: '';
position: absolute;
width: 20px;
height: 1px;
background-color: #8198ae;
bottom: 0;
left: 0;
}
.contacts__info {
    color: #8198ae;
    font-size: 16px;
    margin-bottom: 2px;
}
.contacts__info-link {
    color: #3a9fff;
  text-decoration:none;
}

.form-group {
    margin-bottom: 20px;
display: table;
width: 100%;
  position:relative;
}

.form__field {
    height: 50px;
  position:relative;
    border: none;
    padding: 10px 12px;   
  width: 100%;
  border-radius:6px;
  appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        -ms-appearance:none;
        -o-appearance: none;
  box-shadow: 0 4px 8px 0px rgba(0,0,0,0.2);
  transition: all 0.4s;
          -webkit-transition: all 0.4s;
          -moz-transition: all 0.4s;
          -ms-transition: all 0.4s;
          -o-transition: all 0.4s;
  z-index: 2;
}

.form__select {
    background-image: url('http://www.austinlawrencepartners.com/wp-content/uploads/arrow-down-gray-hi.png');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position-x: 97%;
    width: 100%;
    padding-right: 30px;
    background-position-y: center;
    background-color:#fff;
  color: #8198ae;
}

.form__field--half{
  width:50%;
  float:left;
}

.form__field--half:first-of-type{
  padding-right:15px;
}


.form__textarea{
  height:200px;
}

.btn--up{
  position: absolute;
bottom: -15px;
right: 25px;
  z-index: 3;
}

.career_title {
    color: #fff;
    font-size: 28px;
}

.career_sub {
    font-size: 22px;
    color: #c7cbff;
    margin-top: 0;
    margin-bottom: 30px;
}

.career-img {
    position: absolute;
    bottom: 0;
width: 65%;
z-index: 0;
left: 20%;
}


.footer{
 background-color: #1f4467; 
  padding: 40px 0; 
}

.footer__title{
  font-size: 18px;
margin: 0;
    margin-left: 0px;
margin-left: 10px;
display: inline-block;
line-height: 18px;
vertical-align: middle;
  color:#3a9fff;
}

.footer__light{
   color:#fff;
}

.footer__social {
    float: right;
}

.footer__social-l {
    margin-left: 15px;
    vertical-align: middle;
}

@media screen and (max-width:1024px){
  .collapse{
 display: none;
}
  .navbar-toggle {
    margin: 0;
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
}
  .header__container{
    width: 100%;
    padding: 0;
  }
  .header__menu {
    width: 100%;
}
  .header{
    background-color:#fff; 
    padding: 0;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
  }
  .header__logo{
    padding: 12px 20px 12px 20px;
  }
  .header__el {
    display: block;
    padding: 10px 20px;
    border-top: 1px solid #ededed;
}
  
  .header .btn--white, .header .btn--white:hover{
    padding: 0;
    border: 0;
    box-shadow: none;
    color:#fff;
    background-color:transparent;
  }
  .header__el--blue{
    background-color:#3a9fff;
  }

}


@media screen and (max-width:991px){
  .price-box {
    padding: 0px 10px;
  }
  .carousel-control.left{
    left: -25px;
  }
  .carousel-control.right {
    right: -25px;
}
  .item__content {
    height: auto;
}
}

@media screen and (max-width:767px){
  .footer {
    padding: 25px 0;
  }
  .price-box {
    padding: 0px 35px;
    margin-bottom: 50px;
  }
  .site__img{
    width: 100%;
  }
  .item__content{
    padding: 20px;
   
  }
  .item__description {
    font-size: 12px;
    line-height: 20px;
}
.article-pre__info {
    padding: 25px 10px 0 10px;
    font-size: 14px;
}
  .article-pre__title {
    padding: 10px 10px;
    font-size: 16px;
}
  .form {
    margin-top: 30px;
}
  .form__field--half:first-of-type {
    margin-bottom: 10px;
    padding-right: 0px;
}

.form__field--half {
    width: 100%;
    float: none;
}
  .form-group {
    margin-bottom: 10px;
  }
}



@media screen and (max-width:480px){
  .price-box {
    padding: 0px 20px;
  }
  .carousel-control__img{
    width: 20px;
  }
  .carousel-control.left {
    left: -5px;
  }
  .carousel-control.right {
    right: -5px;
  }
  .partner {
    width: 100%;
  }
  .footer {
    padding: 15px 0;
  }
  .footer__img {
    width: 15px;
  }
  .footer__title {
    font-size: 16px;
    margin-left: 5px;
  }
  .sect {
    padding: 20px 0;
  }
  .sect--padding-top{
    padding-top: 80px;
  }
  .row__sub {
    font-size: 16px;
   }
  .row__title {
    font-size: 20px;
    margin-bottom: 5px;
   }
  .price-box__title {
    font-size: 18px;
    margin-top: 10px;
}
  .price-box__img {
    width: 100px;
    height: 100px;
  }
  .price-box__wrap {
    padding: 20px 20px 30px 20px;
  }
  .price-box__people {
    font-size: 10px;
    font-weight: 400;
  }
  .price-box__discount {
    font-size: 22px;
  }
  .price-box__price {
    font-size: 12px;
  }
  .price-box__feat {
      margin-top: 15px;
      font-size: 14px;
  }
  .price-box__list-el {
    font-size: 12px;
    margin-bottom: 5px;
}
  .site__title {
    font-size: 24px;
}
  .site__subtitle {
    font-size: 18px;
    margin: 10px 0 20px 0;
  }
  .site__img {
    margin-top: 30px;
  }
  .row--margin {
    margin-top: 30px;
}
  .career_title {
    font-size: 18px;
margin-top: 0;
}
  .career_sub {
    font-size: 14px;
    margin-bottom: 20px;
}
  .carousel-indicators {
    display: none;
}
  .item__name {
    font-size: 18px;
}
  .item__description {
    margin-top: 18px;
}
  .article-pre__date {
    font-size: 12px;
    float: none;
}
 

}
