@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap');

.custom_font {
  font-family: 'Source Sans Pro', sans-serif;
}

.safe {
  color: green;
}

.warning {
  color: orange;
  font-size: 30px;
}

.danger {
  color: red;
  font-size: 30px;
}

.human_body {

  background: rgb(250, 250, 250);
  width: 40vw; 
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  border: 200px solid rgb(109, 107, 107);
  */

  display: flex;
  justify-content: center;
  align-items: center;
 

   /* display: relative;
   top:50%;
   left: 50%; */

   filter: none;

}

.bg-eee {
  background: rgb(250, 250, 250);
}

.hover_change:hover {
  /* color:blue; */
  cursor: pointer;
  background-color:rgb(233, 252, 252) ;
  
}

.card-img-top {
  width: 70px;
  height: 76px;
}

.triangle-up {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 13px solid #555;
}

.triangle-down {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 13px solid #555;
}


@media (max-width: 450px) {
  .hidden-xs {
    display: none !important;
  }
}

.testing{
  background-color: red;
}

.cardHighlight:hover
{
  background-color:wheat;
}

.remove-left-margin{
    padding-right: 55px;
    padding-left: 0px;
}

.card-text-fix{

  text-align: left;
  margin-left: 25%;
}

.scroll{
  max-height: 250px;
  overflow-y: scroll;
}

.margin_left{
  margin-left: 35px;
}

.black{
  color: black;
}

.grey{
  color: rgb(143, 142, 142);
}

.pop_up{
  border: none;
  filter: none;
  width: 55%;
}

.download-file-css {
  transition: transform 0.2s;
  margin: 5px 0px 0px 12px;
  color: #f35b04;
  font-size: 22px;
}

.download-file-css:hover {
  margin: 5px 0px 0px 12px;
  color: #f35b04;
  cursor: pointer;
  transform: scale(1.2);
}

.download-file-disable-css {
  margin: 5px 0px 0px 12px;
  font-size: 22px;
  color: grey
}

/* body :not(#unblurred){
  filter: blur(2px);
} */
/* .myModal
{
  height: 200px;
  width: 300px;
  background-color: blue;
} */